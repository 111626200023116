import "./App.css";
import PhaserScene from "./2d";
import React, { useRef, useEffect, useMemo } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { Page1, FormPage, LeaderBroad } from "./overlay";
import { LegerLine, DesktopBlock, PreloadingPage } from "./overlay/components";
import { useStore } from "./store";
function App() {
  const phaserRef = useRef(null);
  const [showPage14, setShowPage14] = React.useState(false);
  const {totalClick , setTotalClick} = useStore();
  useEffect(() => {
    window.setShowPage14 = () => {
      setShowPage14(true);
    };

    window.setTotalClick = (value) => {
      setTotalClick(value);
    };

  
    window.totalClick = totalClick;
  }, [totalClick]);
  return (
    <div className="App"
      style={{
        position : "fixed",
        width : "100svw",
        height : "100svh",
        backgroundColor : "black",
      }}
    >
      <PhaserScene ref={phaserRef} />
      <Routes>
        <Route path="/" element={<Page1 phaserRef={phaserRef} />} />
        <Route path="/formpage" element={<FormPage phaserRef={phaserRef} />} />
        <Route path="/gameplay" element={null} />
        <Route
          path="/leaderbroad"
          element={<LeaderBroad phaserRef={phaserRef} />}
        />
      </Routes>
      <NavHanderler />
      <LegerLine/>
    </div>
  );
}

function NavHanderler({ phaserRef }) {
  const [_phaser, _setPhaser] = React.useState(false);

  // detech current path
  const location = useLocation();
  const nav = useNavigate();
  const path = location.pathname;
  useEffect(() => {
    if (path !== "/") {
      // change path to root
      nav("/");
    }

    window.setPhaser = (value) => {
      _setPhaser(value);
    };

    window.navTo = (path) => {
      nav(path);
    };
  }, []);

  return (
    <>
      <PreloadingPage preload={_phaser} />
    </>
  );
}
export default App;
