import { toHaveDisplayValue } from "@testing-library/jest-dom/matchers";
import Phaser from "phaser";
import FontFaceObserver from "fontfaceobserver";

export class Bootstrap extends Phaser.Scene {
  constructor() {
    super("bootstrap");
  }

  init() {}

  preload() {
    let device = getDevice();
    let fileType = device === "Android" ? ".webp" : ".webp";
    this.load.image("meteor", "/2d/star" + fileType);
    this.load.image("bg", "/2d/bg" + ".webp");
    this.load.image({
      key: "sun",
      url: "/2d/sun3.png",
    });
    this.load.image("sun_without_glow", "/2d/sun3.png");
    this.load.image("cloud", "/2d/cloud2" + fileType);
    this.load.image("cloud1_", "/2d/cloud1_" + fileType);
    this.load.image("cloud2_", "/2d/cloud2_" + fileType);


    this.load.image("bar", "/2d/bar" + fileType);
    this.load.image("neon_circle", "/2d/neon_circle" + fileType);
    this.load.image("land", "/2d/land" + fileType);
    this.load.image("landlanding" , "/2d/landlanding" + ".webp");

    this.load.image("cneon", "/2d/cneo" + ".webp");

    // sfx
    this.load.audio("click", "/sfx/click.mp3");
    this.load.audio("spark", "/sfx/spark.mp3");
    this.load.audio("vibrate", "/sfx/vibrate.mp3");
    this.load.audio("windsound", "/sfx/windsound.mp3");
    this.load.audio("bgm", "/sfx/ambient.mp3");


    this.load.spritesheet("bags", "/2d/bags" + fileType, {
      frameWidth: 304,
      frameHeight: 176,
    });
    this.load.image("logo", "/asset/logo" + ".webp");
    this.load.spritesheet("bagLanding", "/2d/baganims" + ".webp", {
      frameWidth: 300,
      frameHeight: 225,
    });
    this.load.image("bagsentense", "/2d/bagsentense" + fileType);
    this.load.image("gloww", "/2d/glow" + fileType);

    this.load.image("silhouette", "/2d/Bag_Silhouette_Ring" + fileType);

    this.load.spritesheet("clock", "/2d/clock" + fileType, {
      frameWidth: 100,
      frameHeight: 100,
    });

    this.load.spritesheet("cs", "/2d/cs" + fileType, {
      frameWidth: 100,
      frameHeight: 100,
    });

    this.load.spritesheet("hantap", "/2d/hantap" + ".webp", {
      frameWidth: 300,
      frameHeight: 300,
    });

    this.load.image("timeup" , "/2d/end" + ".png");

  }

  create() {
    // play bgm
    this.sound.play("bgm", { loop: true });
    
    this.anims.create({
      key: "baglanding",
      frames: this.anims.generateFrameNumbers("bagLanding", {
        start: 0,
        end: 99,
      }),
      frameRate: 30,

      repeat: -1,
    });

    this.anims.create({
      key: "cs",
      frames: this.anims.generateFrameNumbers("cs", { start: 0, end: 29 }),
      frameRate: 40,
      repeatDelay: 2000,
      repeat: -1,
    });

    this.anims.create({
      key: "hantap",
      frames: this.anims.generateFrameNumbers("hantap", { start: 0, end: 10 }),
      frameRate: 10,
      repeat: -1,
    });

    // landing playscene debug

    var font = new FontFaceObserver("HelveticaLTPro-Bold");
    font.load().then(() => {
      this.scene.start("landing");
      window.setPhaser(true);
    });
  }
}

function getDevice() {
  const userAgent = navigator.userAgent;

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPhone/i.test(userAgent)) {
    return "iPhone";
  }

  return "Unknown";
}
