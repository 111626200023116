import Phaser from "phaser";
import axios from "axios";

export class Debug extends Phaser.Scene {
  constructor() {
    super("debug");
    this.tick = false;
    this.limit = 100;
    this.sequnce = 0;
    this.currentScene = 1;
    this.startTime = Date.now();
    this.gameDuration = 30;
    this.speedRatioClock = 60 / this.gameDuration;
    window.startGame = false;
    window.container = [];
    this.clickCountText = 0
  }

  init() {}

  preload() {}

  create() {


    this.width = this.sys.game.config.width;
    this.height = this.sys.game.config.height;


    // this.bg = this.add.image(this.width / 2, this.height / 2, "bg");
    // this.bg.setDisplaySize(this.height, this.height);
    // this.bg.setDepth(-2);

 
    // bag arr
    this.cloudArr = [];

    this.input.on("pointerdown", (pointer) => {
      window.startGame = true;


      if(window.startGame === true){
      // cs, barC countText bar clock
      this.createCloud();
      if(Math.random() > 0.5){
          this.createCloud();
        }
      }
    });
  }

  createCloud() {
    this.cloud = ["cloud1_" , "cloud2_"];
    let _cloud = this.cloud[Math.floor(Math.random() * this.cloud.length)];
    let randomPos = 1 - Math.random()

    let font = this.add.sprite(this.width * -0.3 , this.height * randomPos , _cloud);
    let __h = this.height * 0.4 + Math.random() * 0.43 * this.height;
    let __w = __h ;

    font.setDisplaySize(__w, __h);
    font.setDepth(1);

    let _ = Math.floor(Math.random() * 3);
    font.setFrame(_);
    // randomAlpha
    font.setAlpha(Math.random() * 0.5 + 0.5);

    this.cloudArr.push({
      cloud: font,
      speed: Math.random() * 10 + 7,
      randomSpeed : Math.random() * 0.6 + 0.5,
    });
  }



  update(time, delta) {


    // if bag length is more than 1
    if (this.cloudArr.length > 0) {
      this.cloudArr.forEach((info, index) => {
        info.cloud.x += 1 * info.speed * info.randomSpeed;

        
        if (info.cloud.y < -this.height * 0.20) {
          info.cloud.destroy();
          this.cloudArr.splice(index, 1);
        }
      });
    }
  }
}
