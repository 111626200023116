import { Wraper } from "./helper";
import { Button, ButtonRound } from "./components";
import { useNavigate } from "react-router-dom";
import { useStore } from "../store";
import { useRef } from "react";
const Page1 = (props) => {
  const { phaserRef } = props;
  const { setplayAnimation } = useStore();
  const containerRef = useRef(null);
  const nav = useNavigate();

  return (
    <Wraper style={{}}>
      <div
        ref={containerRef}

        style={{
          transition: "0.8s",
        }}
      >
        <div className="block" style={{ height: "68svh" }} />

        <ButtonRound
          onClick={() => {
            let item = localStorage.getItem("info2")
            if(item === null){
              nav("/formpage");
            }
            else{
              containerRef.current.style.opacity = 0;

              setTimeout(()=>{
                phaserRef.current.scene.scenes[1].sceneTransition();
              }, 500);

              setTimeout(() => {
                nav("/gameplay");
              }, 1000);
            }
          }}
          id = {"startButton"}
        >
          PLAY
        </ButtonRound>

        </div>
    </Wraper>
  );
};

export default Page1;
