import _Page1 from "./page1";
import _FormPage from "./formPage";
import _Page14 from "./page14";
import _Page15 from "./page15";
import _LeaderBroad from "./components/leaderBroad";

export const Page1 = _Page1;
export const Page14 = _Page14;
export const Page15 = _Page15;
export const FormPage = _FormPage;
export const LeaderBroad = _LeaderBroad;
