import Phaser from "phaser";
import axios from "axios";
export class Landing extends Phaser.Scene {
  constructor() {
    super("landing");
    this.currentTime = Date.now();
  }

  init() {}

  preload() {}

  create() {
    this.cloudArr = [];
    this.width = this.sys.game.config.width;
    this.height = this.sys.game.config.height;


    this.bg = this.add.image(this.width / 2, this.height / 2, "bg");
    this.bg.setDisplaySize(this.height, this.height);
    this.bg.setDepth(-2);

    let originX = this.width / 2;
    let originY = this.height * 0.35;

    this.bag = this.add.sprite(originX, originY, "bagLanding");
    this.bag.setDepth(3);
    this.bag.setDisplaySize(this.height * 0.25 * 1.333, this.height * 0.25);
    this.bag.anims.play("baglanding");
    this.gloww = this.add.image(originX, originY, "gloww");
    this.gloww.setDepth(1);
    this.gloww.setDisplaySize(this.height * 1, this.height * 1);

    this.bagsetense = this.add.image(originX, originY, "bagsentense");
    this.bagsetense.setDisplaySize(this.height * 0.4 * 1.06, this.height * 0.4);
    this.bagsetense.setDepth(2);

    // logo
    this.logo = this.add.image(this.width * 0.5, this.height * 0.075, "logo");
    this.logo.setDisplaySize(this.height * 0.04 * 3.67, this.height * 0.04);


    // this.bird = this.add.sprite(this.width * 0.5, this.height * 0.2, "bridfry");
    // this.bird.anims.play("birdflying");
    // this.bird.setDepth(-1);
    // this.bird.setDisplaySize(this.width, this.width * 3 /4);


    this.tweens.add({
      targets: this.bagsetense,
      angle: 360,
      duration: 15000,
      repeat: -1,
    });


    let oriLangX = this.width * 0.5;
    let oriLangY = this.height - this.height * 0.18;
    this.land = this.add.sprite(oriLangX, oriLangY, "landlanding");
    this.land.setDisplaySize(this.height * 0.38 * 2.37, this.height * 0.38);
    this.land.setDepth(400);

    this.light = this.lights.addLight(0, 0, 200).setScrollFactor(0.0);


    for (let i = 0; i < 5; i++) {
      this.createCloud();
    }
  }

  createCloud() {
    this.cloud = ["cloud1_" , "cloud2_"];
    let _cloud = this.cloud[Math.floor(Math.random() * this.cloud.length)];
    let randomPos = 1 - Math.random()

    let font = this.add.sprite(this.width * -0.3 , this.height * randomPos , _cloud);
    let __h = this.height * 0.4 + Math.random() * 0.43 * this.height;
    let __w = __h ;

    font.setDisplaySize(__w, __h);
    font.setDepth(1);

    this.cloudArr.push({
      cloud: font,
      speed: Math.random() * 5 + 3,
      randomSpeed : Math.random() * 0.3 + 0.25,
    });
  }


  sceneTransition() {
    // play windsound
    this.sound.play("windsound", { loop: false });
    this.tweens.add({
      targets: [this.gloww , this.bag],
      alpha: 0,
      duration: 1500,
      repeat: 0,
    });
    this.cloud = this.add.image(-this.width , this.height * 0.5, "cloud");
    this.cloud.setDisplaySize(this.height, this.height);
    this.cloud.setDepth(1500);    

    this.tweens.add({
      targets: this.cloud,
      x: this.width * 2,
      duration: 1800,
      repeat: 0,
    });

    const fx = this.cameras.main.postFX.addWipe();

    this.scene.transition({
      target: 'playscene',
      duration: 1800,
      moveBelow: true,
      onUpdate: (progress) => {
          fx.progress = progress;
        }
    });
  }

  update(time, delta) {
    if(Date.now() - this.currentTime > 1000 / 1.1){
      this.currentTime = Date.now();
      this.createCloud();
    }

    if (this.cloudArr.length > 0) {
      this.cloudArr.forEach((info, index) => {
        info.cloud.x += 1 * info.speed * info.randomSpeed;

        
        if (info.cloud.x > this.width * 1.3) {
          info.cloud.destroy();
          this.cloudArr.splice(index, 1);
        }
      });
    }
  }
}
